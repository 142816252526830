import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Icon } from 'wizard-ui';

function TopAlert(props: any) {
  const { title, icon, style } = props;
  return (
    <div className="TopAlert">
      <Alert bsStyle={style}>
        <Icon type={icon} />
        {title}
      </Alert>
    </div>
  );
}

TopAlert.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.string,
};

TopAlert.defaultProps = {
  icon: 'warning',
  style: 'danger',
};

export default TopAlert;
