import React from 'react';

export type Modal = {
  title: string;
  onHide: any;
  content?: any;
  confirmText?: string;
  mode?: string;
  disabled?: boolean;
  bsSize?: 'sm' | 'lg';
};

const ModalContext = React.createContext({
  modal: [] as Modal[],
  pushModal: (m: Modal) => {},
  popModal: () => {},
});
export default ModalContext;
