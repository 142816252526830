import store from 'store';

export const SETTING_STORE = `setting`;

export const INIT_STEEING = {
    // 分段
    partition: {
        isUploadSplit: true,
        uploadFileSize: 100,
        uploadFileUnit: 'MB',
        uploadSplitSize: 32,
        uploadSplitUnit: 'MB',
        isDownloadSplit: true,
        downloadFileSize: 100,
        downloadFileUnit: 'MB',
        downloadSplitSize: 8,
        downloadSplitUnit: 'MB',
    },
    // 并发
    concurrent: {
        uploadConcurrent: 5,
        downloadConcurrent: 5,
        concurrent: 5,
    },
    // 加密
    encryption: false,
}

type UNIT_Type = 'MB' | 'GB';

export type SettingType = {
    // 分段
    partition: {
        isUploadSplit: boolean,
        uploadFileSize?: number,
        uploadFileUnit: UNIT_Type,
        uploadSplitSize?: number,
        uploadSplitUnit: UNIT_Type,
        isDownloadSplit: boolean,
        downloadFileSize?: number,
        downloadFileUnit: UNIT_Type,
        downloadSplitSize?: number,
        downloadSplitUnit?: UNIT_Type,
    },
    // 并发
    concurrent: {
        uploadConcurrent: number,
        downloadConcurrent: number,
        concurrent: number,
    },
    // 加密
    encryption: boolean,
}

export const getSetting = ():SettingType => {
  return store.get(SETTING_STORE) || INIT_STEEING;
}

export const updateSetting = (s: object) => {
    const setting = getSetting();
    store.set(SETTING_STORE, {
        ...setting,
        ...s,
    })
}