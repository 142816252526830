const PREFIX = 'S3C_1.0.100.2';
export default () => {
  // const date = new Date('2020-05-25');
  // let year = String(date.getFullYear());
  // year = String(year);
  // year = year.substr(year.length - 2);
  // const mm = date.getMonth() + 1; // getMonth() is zero-based
  // const dd = date.getDate();
  // const suffix = [
  //   year,
  //   (mm > 9 ? '' : '0') + mm,
  //   (dd > 9 ? '' : '0') + dd
  // ].join('');
  // const beta = '';
  return PREFIX;
}
