export const RES_NAMES = {
  bucket: '存储桶',
  object: '文件',
  folder: '文件夹',
  account: '账户'
}

export const PERMISSION = {
  'READ': '列出文件',
  'WRITE': '写/删文件',
  'READ_ACP': '读取 ACL 权限',
  'WRITE_ACP': '写/删桶 ACL 权限',
  'FULL_CONTROL': '完全控制',
}

export const OBJECT_PERMISSION = {
  'READ': '读取文件',
  'WRITE': '写/删文件',
  'READ_ACP': '读取文件 ACL 权限',
  'WRITE_ACP': '写/删文件 ACL 权限',
  'FULL_CONTROL': '完全控制',
}

export const ACLS = {
  'private': ['私有', '其他用户在未经授权的情况下均无权访问。'],
  'public-read': ['公开读', '所有人都可以匿名查看桶中的文件。', '注意：公开读权限可以通过匿名身份直接查看桶中的文件列表。存在一定的风险，不推荐此配置，建议选择私有。'],
  'public-read-write': ['公开读写', '所有人都可以匿名在存储桶中上传、查看和删除文件。', '注意：公开读写权限可以通过匿名身份直接上传，查看和删除存储桶中的文件。存在一定的安全风险，不推荐此配置，建议选择私有。'],
  // 'aws-exec-read': '',
  // 'authenticated-read': '',
  // 'bucket-owner-read': '',
  // 'bucket-owner-full-control': ''
}

export const STORAGE_CLASS = {
  'STANDARD': '标准存储'
}

export const MB10 = 10 * 1024 * 1024;

export type StorageClassType = keyof typeof STORAGE_CLASS;

export const Versions = {
  'Closed': '未开启',
  'Enabled': '启用',
  'Suspended': '暂停',
}

export const XMLNS = 'http://s3.amazonaws.com/doc/2006-03-01/'

export const XSI_XMLNS = 'http://www.w3.org/2001/XMLSchema-instance';
export const LOG_XMLNS = 'http://acs.amazonaws.com/groups/s3/LogDelivery';
export const ALL_USER_URI = 'http://acs.amazonaws.com/groups/global/AllUsers';

export type VersionType = keyof typeof Versions;

export type ResNameType = keyof typeof RES_NAMES;
export type AclType = keyof typeof ACLS;

export const OBJECT_ACLS = {
  'bucket-owner-full-control': '继承桶权限',
  'private': '私有',
  'public-read': '读取文件'
}

export type ObjectAclType = keyof typeof OBJECT_ACLS;

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

export const GB160 = (1 << 30) * 160;