
import request from '../utils/request';
import { bufferToString, fileToArrayBuffer, appendBuffer } from '../utils/file-format';
import uuid from '../utils/uuid';
import { addObjectMessage, setObjectMessage, fetchObjectMessage, checkObjectMessage } from '../utils/object-message';
import { part2xml } from '../utils/json2xml';
import fileDownload from 'js-file-download';
import xml2js from 'xml2js';
import md5 from 'js-md5';
import { StorageClassType } from '../constants';
import { Error, Query, Params, GrantAclType, OwnerType } from './type';
import { getSetting } from '../utils/setting';
import xbytes from '../utils/xbytes';
import axios from 'axios';
import PQueue from 'p-queue';
import throttle from '../utils/throttle';
import speed from '../utils/speed';
import lodash from 'lodash';

export type ObjectType = {
  key: string;
  etag: string;
  lastmodified: string;
  size: string;
  storageclass: StorageClassType;
}

type PrefixType = {
  prefix: string
}

export type GetObjectType = {
  data: {
    istruncated: 'false' | 'true';
    maxkeys: string;
    prefix: string;
    name: string;
    marker: any;
    contents: ObjectType[] | ObjectType
    commonprefixes?: PrefixType[] | PrefixType
    keycount?: string;
    nextcontinuationtoken?: string;
  }
}

export type ObjectRltType = {
  istruncated?: boolean;
  contents: ObjectType[]
  prefixs?: PrefixType[]
  prefix?: string;
  keyCount?: number
  continuationToken?: string;
  error?: string;
}
export const listObjects = async (params: Params, cb?: (values: ObjectRltType) => void): Promise<ObjectRltType> => {
  const { bucket, prefix, delimiter, startAfter, maxKeys, continuationToken } = params;
  const query: Query = {
    'list-type': 2,
    // 'fetch-owner': true
  };
  if (prefix) {
    query.prefix = prefix;
  }
  if (delimiter) {
    query.delimiter = delimiter;
  }
  if (startAfter) {
    query['start-after'] = startAfter;
  }
  if (maxKeys) {
    query['max-keys'] = maxKeys;
  }
  if (continuationToken) {
    query['continuation-token'] = continuationToken;
  }
  const rlt = await request({
    pathname: `/${bucket}`,
    query,
  }) as (GetObjectType | Error);
  let error;
  let keyCount = 0;
  let contents: ObjectType[] = [];
  let prefixs: PrefixType[] = [];
  let cPrefix;
  let istruncated = false;
  let cNextcontinuationtoken;
  if ((rlt as Error).error) {
    error = (rlt as Error ).error
  } else {
    const cRlt = rlt as GetObjectType;
    istruncated = cRlt.data.istruncated === 'true';
    cPrefix = cRlt.data.prefix;
    cNextcontinuationtoken = cRlt.data.nextcontinuationtoken;
    const preContents = cRlt.data.contents;
    const commonprefixes = cRlt.data.commonprefixes;
    keyCount = Number(cRlt.data.keycount);
    contents = (!Array.isArray(preContents) ? (preContents ? [preContents] : []) : preContents);
    prefixs = (!Array.isArray(commonprefixes) ? (commonprefixes ? [commonprefixes] : []) : commonprefixes);
  }

  const result = {
    error,
    istruncated,
    contents,
    prefixs,
    prefix: cPrefix,
    keyCount,
    continuationToken: cNextcontinuationtoken,
  }
  if(cb) {
    cb(result);
  }
  return result;
}

export type Deletemarker = {
  islatest: 'false' | 'true';
  key: string;
  lastmodified: string;
  versionid: string;
}
export type ObjectVersionType = {
  key: string;
  etag: string;
  islatest: 'true' | 'false';
  lastmodified: string;
  size: string;
  storageclass: StorageClassType;
  versionid: string;
}
export type ListObjectVersionsType = {
  data: {
    istruncated: 'false' | 'true';
    maxkeys: string;
    prefix: string;
    name: string;
    marker: any;
    version: ObjectVersionType[] | ObjectVersionType
    commonprefixes?: PrefixType[] | PrefixType
    deletemarker?: Deletemarker[] | Deletemarker
    nextkeymarker?: string;
    nextversionidmarker?: string;
  }
}
export type ObjectVersionsRltType = {
  istruncated?: boolean;
  versions: ObjectVersionType[]
  prefixs?: PrefixType[]
  deletemarkers?: Deletemarker[]
  prefix?: string;
  nextkeymarker?: string;
  nextversionidmarker?: string;
}
export const listObjectVersions = async (params: Params): Promise<ObjectVersionsRltType | Error> => {
  const { bucket, prefix, delimiter, versionid, startAfter, maxKeys, nextkeymarker, nextversionidmarker } = params;
  const query: Query = { versions: '' };
  if (prefix) {
    query.prefix = prefix;
  }
  if (delimiter) {
    query.delimiter = delimiter;
  }
  if (versionid) {
    query.versionId = versionid;
  }
  if (startAfter) {
    query['start-after'] = startAfter;
  }
  if (maxKeys) {
    query['max-keys'] = maxKeys;
  }
  if (nextkeymarker) {
    query['key-marker'] = nextkeymarker;
  }
  if (nextversionidmarker) {
    query['versionid-marker'] = nextversionidmarker;
  }
  const rlt: any = await request({
    pathname: `/${bucket}`,
    query,
  });
  if (rlt.error) {
    return rlt;
  } else {
    const istruncated = rlt.data.istruncated === 'true';
    const preVersions = rlt.data.version;
    const commonprefixes = rlt.data.commonprefixes;
    const preDeletemarkers = rlt.data.deletemarker;
    let versions = (!Array.isArray(preVersions) ? (preVersions ? [preVersions] : []) : preVersions);
    const prefixs = (!Array.isArray(commonprefixes) ? (commonprefixes ? [commonprefixes] : []) : commonprefixes);
    const deletemarkers = (!Array.isArray(preDeletemarkers) ? (preDeletemarkers ? [preDeletemarkers] : []) : preDeletemarkers);
    const result = {
      istruncated,
      versions,
      prefixs,
      deletemarkers,
      prefix: rlt.data.prefix,
      nextkeymarker: rlt.data.nextkeymarker,
      nextversionidmarker: rlt.data.nextversionidmarker,
    }
    return result;
  }
}

export const putObject = async (params: Params, cb?: (value?: any) => void) => {
  const { bucket, prefix, file, filename, acl, onUploadProgress, cancelToken } = params;
  let headers: any = {};
  if (acl) {
    headers['x-amz-acl'] = acl;
  }
  let path;
  let fileArrBuffer: Uint8Array | '' = '';
  if (file) {
    // File 对象有 path 属性
    // @ts-ignore
    path = file.path;
    path = path[0] === '/' ? path.slice(1, path.length) : path;
    // 上传文件内容完整性校验
    let extra = {};
    if (file.size) {
      fileArrBuffer = await fileToArrayBuffer(file as File | Blob);
      const eTag = md5(fileArrBuffer);
      extra = {
        'content-encoding': 'identity',
        'ETag': eTag,
      }
    }
    headers = {
      ...headers,
      'content-type': file.type,
      ...extra,
    };
  }
  // 服务端加密
  const { encryption } = getSetting();
  if (encryption) {
    headers['x-amz-server-side-encryption'] = 'AES256';
  }
  const objectName = filename || path;

  const rlt = await request({
    method: 'PUT',
    pathname: (`/${bucket}${prefix === '/' ? '/' : prefix ? '/' + prefix : ''}/${objectName}`).replace('///', '//'),
    headers,
    onUploadProgress,
    body: fileArrBuffer,
    file,
    cancelToken,
  });
  if (cb) {
    cb();
  }
  return rlt;
}

export const putObjectAcl = async (params: Params) => {
  const { body, bucket, prefix, object } = params;
  return await request({
    method: 'PUT',
    pathname: `/${bucket}${prefix ? '/' + prefix : ''}${object ? `/${object}` : ''}`,
    query: { acl: '' },
    body,
  });
}

type DeleteObjectRltType = {
  key: string;
  versionid?: string;
}
export const deleteObjects = async (params: Params, contents: DeleteObjectRltType[]) => {
  const { bucket } = params;
  const keys = contents.map(c => {
    const content: any = {
      'Object': {
        'Key': c.key,
      }
    }
    if (c.versionid) {
      content['Object']['VersionId'] = c.versionid
    }
    return content;
  });
  const builder = new xml2js.Builder();
  const body = builder.buildObject({
    'Delete': keys
  });
  let error;
  const rlt: any = await request({
    method: 'POST',
    pathname: `/${bucket}`,
    query: { delete: '' },
    body,
  });
  let errors = [];
  let deleted = [];
  if (rlt.error || rlt.statusText !== 'OK') {
    error = rlt.error || rlt.statusText;
  } else {
    const preDeleted = lodash.get(rlt, 'data.deleted');
    const preError = lodash.get(rlt, 'data.error')
    errors = (!Array.isArray(preError) ? (preError ? [preError] : []) : preError);
    deleted = (!Array.isArray(preDeleted) ? (preDeleted ? [preDeleted] : []) : preDeleted);
  }
  return {
    error,
    errors,
    deleted,
  };
}

export type GetObjectAclType = {
  data: {
    owner: OwnerType,
    accesscontrollist: {
      grant: GrantAclType | GrantAclType[]
    } | null
  }
}

export type GetObjectAclRltType = {
  error?: string;
  acls: GrantAclType[];
  owner?: OwnerType,
}

export const getObjectAcl = async (params: Params, cb?: (values: any) => void): Promise<GetObjectAclRltType> => {
  const { bucket, prefix, versionid } = params;
  const query: Query = { acl: '' }
  if (versionid) {
    query.versionId = versionid;
  }
  const rlt = await request({
    pathname: `/${bucket}${prefix ? '/' + prefix : ''}`,
    query
  }) as (GetObjectAclType | Error);
  let error;
  let acls: GrantAclType[] = [];
  let owner;
  if ((rlt as Error).error) {
    error = (rlt as Error).error
  } else {
    const cRlt = rlt as GetObjectAclType;
    if (cRlt.data.accesscontrollist) {
      const { data: { accesscontrollist: { grant } } } = cRlt;
      acls = Array.isArray(grant) ? grant : grant ? [grant] : [];
    }
    owner = cRlt.data.owner
  }
  return {
    error,
    acls,
    owner,
  };
}

export const copyObject = async (params: Params, isPaste = false, sameObjectSet = 'cover') => {
  const { bucket, prefix } = params;
  let headers: any = params.headers;
  // headers x-amz-copy-source
  // if (versionid) {
  //   headers['x-amz-version-id'] = versionid
  // }
  // 服务端加密
  const { encryption } = getSetting();
  if (encryption) {
    headers['x-amz-server-side-encryption'] = 'AES256';
  }
  const isCover = sameObjectSet === 'cover';
  const isBoth = sameObjectSet === 'both';
  const isIgnore = sameObjectSet === 'ignore';
  let pathname = `/${bucket}${prefix ? '/' + prefix : ''}`;
  // 粘贴根目录键值去掉前面的 /
  if (isPaste) {
    pathname = pathname.replace('//', '/');
  }
  // 直接覆盖
  if (isCover) {
    const rlt = await request({
      method: 'PUT',
      pathname,
      headers,
    });
    return rlt;
  } else {
    const curObject = await listObjects({ bucket, prefix: prefix });
    const isSame = curObject.contents.length > 0;
    // 存在同名文件、并且保留两者，加（uuid）
    if (isSame && isBoth) {
      const prefixArr = prefix ? prefix.split('.') : [];
      const suffix = `.${prefixArr[prefixArr.length - 1]}`;
      // 无后缀
      if (prefixArr.length === 1) {
        pathname = `/${bucket}${prefix ? '/' + prefix : ''}(${uuid()})`;
      } else {
        pathname = `/${bucket}${prefix ? '/' + prefix : ''}`.replace(suffix, `(${uuid()})${suffix}`);
      }
      // 粘贴根目录键值去掉前面的 /
      if (isPaste) {
        pathname = pathname.replace('//', '/');
      }
      const rlt = await request({
        method: 'PUT',
        pathname,
        headers,
      });
      return rlt;
      // 不同名且保留原文件
      // 不同名且保留两者
    } else if (!isSame && (isIgnore || isBoth)) {
      const rlt = await request({
        method: 'PUT',
        pathname,
        headers,
      });
      return rlt;
    }
  }
}

export type ObjectPreviewRtType = {
  error?: string;
  base64?: string;
}
export const getObjectPreview = async (params: Params, cb?: (values: ObjectPreviewRtType) => void) => {
  const { bucket, prefix } = params;
  const preRlt: any = await request({
    pathname: `/${bucket}/${prefix}`,
    // 图片文件 Get 请求时需要携带
    // https://github.com/axios/axios/issues/513
    responseType: 'arraybuffer',
  });
  if (cb) {
    if (preRlt && preRlt.statusText !== 'OK') {
      cb({ error: preRlt.error || '无法预览图片' })
    } else {
      const buffer = preRlt.request.response;
      const type = preRlt.headers['content-type'];
      const base64 = bufferToString(buffer, type);
      cb({ base64 })
    }
  }
}

export const singleObjectUpload = async (params: Params, file: File | Blob, extraPath: string, curPrefix: string, acl: string, getObjectMessage: Function, UPLOAD_KEY: string, uid?: string) => {
  const { bucket } = params;
  let path = (file as any).path;
  path = path[0] === '/' ? path.slice(1, path.length) : path;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const cancelToken = source.token;
  setObjectMessage({
    key: UPLOAD_KEY,
    rlt: 'process',
  })
  window.addEventListener('offline', () => {
    source.cancel(`网络错误，上传任务失败`);
  });
  const interval = setInterval(() => {
    const isExist = checkObjectMessage(UPLOAD_KEY);
    if (!isExist) {
      clearInterval(interval);
      source.cancel(`任务删除，下载任务取消`);
    }
  }, 2000);
  const startedAt = new Date().getTime();
  const updateObjectMessage = throttle(() => {
    const cur: any = fetchObjectMessage(UPLOAD_KEY);
    const curTime = new Date().getTime();
    const time = curTime - (cur.preTime || curTime) || 1000;
    const loaded = cur.addSize - (cur.preSize || 0);
    const totalTime = curTime - startedAt;
    const preSize = cur.addSize;
    setObjectMessage({
      key: UPLOAD_KEY,
      totalTime,
      preTime: curTime,
      preSize,
      time,
      speed: speed(loaded, time, cur.speed || 0, totalTime, cur.addSize)
    });
    getObjectMessage();
  }, 1000);
  const formatAcl = acl.replace('default-', '');
  const { concurrent: { uploadConcurrent }, partition: { uploadFileSize, uploadFileUnit, uploadSplitSize, uploadSplitUnit, isUploadSplit }, encryption } = getSetting();
  const fileMaxSize = xbytes(
    `${uploadFileSize}${uploadFileUnit}`,
  ) as number;
  const piece = xbytes(
    `${uploadSplitSize}${uploadSplitUnit}`,
  ) as number;
  // path = decode(path);
  const size = file.size;
  getObjectMessage();
  // 分片上传
  if (isUploadSplit && size >= fileMaxSize) {
    console.log('正在分段上传...');
    let headers: any = { 'x-amz-acl': formatAcl };
    if (encryption) {
      headers['x-amz-server-side-encryption'] = 'AES256';
    }
    // S3 API InitiateMultipartUpload
    const createRlt: any = await request({
      method: 'POST',
      pathname: `/${bucket}${extraPath}/${path}`,
      query: { uploads: '' },
      headers,
    });
    if (createRlt && createRlt.error) {
      return createRlt.error;
    } else {
      setObjectMessage({
        key: UPLOAD_KEY,
        uploadid: createRlt.data.uploadid,
        process: [{ path, uploadid: createRlt.data.uploadid }],
        currSize: 0,
      });
      const parts: { partNumber: number; eTag: string; }[] = [];
      const size = file.size;
      const times = Math.ceil(size / piece);
      const concurrency = Number(uploadConcurrent);
      const partQueue = new PQueue({ concurrency, autoStart: false });
      const partErrors: string[] = [];
      for (let k = 0; k < times; k++) {
        partQueue.add(async () => {
          const currentLen = k * piece;
          //文件 File 对象是 Blob 对象的子类，Blob 对象包含一个重要的方法slice，通过这个方法，就可以对二进制文件进行拆分。
          const currentBody = (k === times - 1) ? file.slice(currentLen) : file.slice(currentLen, currentLen + piece);
          let preLoaded = 0;
          const uploadPartRlt: any = await uploadPart({
            bucket,
            filename: createRlt.data.key,
            uploadid: createRlt.data.uploadid,
            partnumber: k + 1,
            cancelToken,
            part: currentBody,
            // https://github.com/aws/aws-sdk-js/blob/master/lib/s3/managed_upload.js#L686
            onUploadProgress: progressEvent => {
              setObjectMessage({
                key: UPLOAD_KEY,
                part: `${k + 1}`,
                currSize: Number(progressEvent.loaded) - preLoaded,
              });
              updateObjectMessage();
              preLoaded = Number(progressEvent.loaded);
            },
          });
          if (uploadPartRlt && uploadPartRlt.error) {
            partErrors.push(uploadPartRlt.error);
            partQueue.clear();
          } else {
            const cEtag = uploadPartRlt.headers.etag;
            parts.push({
              partNumber: k + 1,
              eTag: cEtag,
            });
          }
        });
      }
      await partQueue.start().onIdle();
      partQueue.clear();
      clearInterval(interval);
      // S3 API CompleteMultipartUpload
      const completeRlt = await request({
        method: 'POST',
        pathname: `/${bucket}/${createRlt.data.key}`,
        query: { uploadId: createRlt.data.uploadid },
        body: part2xml(parts),
      });
      if (completeRlt && completeRlt.error) {
        setObjectMessage({
          key: UPLOAD_KEY,
          rlt: 'fail',
        });
      } else {
        setObjectMessage({
          key: UPLOAD_KEY,
          success: 1,
          part: '',
          rlt: 'success',
        });
      }
      getObjectMessage();
    }
  } else {
    // S3 API PutObject
    let preLoaded = 0;
    const putRlt: any = await putObject({
      bucket,
      prefix: curPrefix,
      file,
      acl: formatAcl,
      cancelToken,
      onUploadProgress: progressEvent => {
        setObjectMessage({
          key: UPLOAD_KEY,
          currSize: Number(progressEvent.loaded) - preLoaded,
        });
        updateObjectMessage();
        preLoaded = Number(progressEvent.loaded);
      },
    });
    if (putRlt && putRlt.error) {
      setObjectMessage({
        key: UPLOAD_KEY,
        rlt: 'fail',
      });
    } else {
      setObjectMessage({
        key: UPLOAD_KEY,
        success: 1,
        rlt: 'success',
      });
    }
    getObjectMessage();
  }
}

export const objectUpload = async (params: Params, files: any[], acl: string, extraPath: string, curPrefix: string, getObjectMessage: Function, queue: any, setQueue: any, uid?: string) => {
  let pollingErrors: string[] = [];
  const uploadIds: string[] = [];
  const { partition: { uploadFileSize, uploadFileUnit } } = getSetting();
  const threshold = xbytes(`${uploadFileSize}${uploadFileUnit}`) as number;
  files.forEach(file => {
    const UPLOAD_KEY = uuid();
    uploadIds.push(UPLOAD_KEY);
    let path = (file as any).path;
    path = path[0] === '/' ? path.slice(1, path.length) : path;
    const { bucket } = params;
    addObjectMessage({
      key: UPLOAD_KEY,
      action: 'upload',
      rlt: 'pre-process',
      uuid: uid,
      path: `/${bucket}${extraPath}`,
      objectName: path,
      totalSize: file.size,
      currSize: 0,
      success: 0,
    });
    if (file.size < threshold) {
      queue.small.add(async () => {
        const rlt = await singleObjectUpload(params, file, extraPath, curPrefix, acl, getObjectMessage, UPLOAD_KEY, uid);
        if (rlt) {
          pollingErrors.push(rlt);
        }
      })
    } else {
      queue.large.add(async () => {
        const rlt = await singleObjectUpload(params, file, extraPath, curPrefix, acl, getObjectMessage, UPLOAD_KEY, uid);
        if (rlt) {
          pollingErrors.push(rlt);
        }
      })
    }
  });
  setQueue(queue);
  getObjectMessage();
  return pollingErrors;
}

// https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.35
export const rangeObject = async (params: Params, start: number, end: number, DOWNLOAD_KEY: string, partNumber: number, getObjectMessage: Function) => {
  const { bucket, key, cancelToken } = params;
  let preLoaded = 0;
  let time = 0;
  const rlt = await request({
    pathname: '/' + bucket + '/' + key,
    responseType: 'blob',
    headers: {
      Range: `bytes=${start}-${end}`,
      // 'response-cache-control': 'No-cache',
      // partNumber,
    },
    cancelToken,
    onDownloadProgress: (progressEvent:any) => {
      setObjectMessage({
        key: DOWNLOAD_KEY,
        currSize: Number(progressEvent.loaded) - preLoaded,
        time: Number(progressEvent.timeStamp) - time,
      });
      getObjectMessage();
      preLoaded = Number(progressEvent.loaded);
      time = Number(progressEvent.timeStamp);
    },
  })
  return rlt;
}

export const objectDownload = async (params: Params, size: number, getObjectMessage: Function) => {
  const { partition: { isDownloadSplit ,downloadFileSize, downloadFileUnit, downloadSplitSize, downloadSplitUnit } } = getSetting();
  const limitSize = xbytes(`${downloadFileSize}${downloadFileUnit}`) as number;
  const piece = xbytes(`${downloadSplitSize}${downloadSplitUnit}`) as number;
  const { bucket, key } = params;
  const keyArr = (key as string).split('/');
  const objectName = keyArr[keyArr.length - 1];
  const DOWNLOAD_KEY = uuid();
  addObjectMessage({
    key: DOWNLOAD_KEY,
    action: 'download',
    rlt: 'process',
    path: '/' + bucket + '/' + key,
    objectName,
    totalSize: size,
    currSize: 0,
    success: 0,
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const cancelToken = source.token;
  window.addEventListener('offline', () => {
    source.cancel(`网络错误，下载任务失败`);
  });
  const interval = setInterval(() => {
    const isExist = checkObjectMessage(DOWNLOAD_KEY);
    if (!isExist) {
      clearInterval(interval);
      source.cancel(`任务删除，下载任务取消`);
    }
  }, 2000);
  if (isDownloadSplit && size >= limitSize) {
    console.log('正在分段下载...');
    const times = Math.ceil(size / piece);
    let data = [];
    for(let i = 0; i < times; i++) {
      // 移除任务后停止上传
      const isCurExist = checkObjectMessage(DOWNLOAD_KEY);
      if (!isCurExist) {
        break;
      }
      const start = i * piece;
      const end = Math.min(size, (i + 1) * piece) -1;
      const rangeRlt:any = await rangeObject({ ...params, cancelToken }, start, end, DOWNLOAD_KEY, i + 1, getObjectMessage);
      if (rangeRlt.error || (rangeRlt.status !== 200 && rangeRlt.status !== 206)) {
        setObjectMessage({
          key: DOWNLOAD_KEY,
          rlt: 'fail',
        });
        getObjectMessage();
        return {
          ...rangeRlt,
          error: rangeRlt.error || rangeRlt.statusText || '分段下载失败'
        }
      } else {
        data.push(rangeRlt.request.response);
      }
    }
    clearInterval(interval);
    let curAB = new Uint8Array([]);
    for(let di = 0; di < data.length; di++) {
      const fileArrBuffer = await fileToArrayBuffer(data[di] as File | Blob);
      curAB = appendBuffer(curAB, fileArrBuffer);
    }
    fileDownload(curAB, objectName);
    setObjectMessage({
      key: DOWNLOAD_KEY,
      rlt: 'success',
    });
    getObjectMessage();
    return { status: 200 };
  } else {
    return await signleObjectDownload({ ...params, cancelToken }, DOWNLOAD_KEY, size, interval, getObjectMessage);
    // let curUrl = getSignedUrl(bucket, key as string);
    // downloadFile(curUrl, objectName, size);
  }
}

export const signleObjectDownload = async (params: Params, DOWNLOAD_KEY: string, size: number,  interval: any, getObjectMessage: Function, cb?: (values: ObjectPreviewRtType) => void) => {
  const { bucket, key, versionid, cancelToken } = params;
  const keyArr = (key as string).split('/');
  const objectName = keyArr[keyArr.length - 1];
  const query: Query = {};
  // 支持下载对象的历史版本
  if (versionid) {
    query.versions = '';
    query.versionId = versionid
  }
  let preLoaded = 0;
  let time = 0;
  const rlt: any = await request({
    pathname: '/' + bucket + '/' + key,
    // 下载文件必须
    responseType: 'blob',
    cancelToken,
    onDownloadProgress: (progressEvent:any) => {
      setObjectMessage({
        key: DOWNLOAD_KEY,
        currSize: Number(progressEvent.loaded) - preLoaded,
        time: Number(progressEvent.timeStamp) - time,
      });
      getObjectMessage();
      preLoaded = Number(progressEvent.loaded);
      time = Number(progressEvent.timeStamp);
    },
  });
  if (rlt.error || rlt.status !== 200) {
    setObjectMessage({
      key: DOWNLOAD_KEY,
      rlt: 'fail',
    });
    getObjectMessage();
    return {
      ...rlt,
      error: rlt.error || '下载失败'
    };
  } else {
    setObjectMessage({
      key: DOWNLOAD_KEY,
      rlt: 'success',
    });
    getObjectMessage();
    fileDownload(rlt.request.response, objectName);
    return rlt;
  }
}

// 大文件上传

// S3 API InitiateMultipartUpload

export type InitMultipartUploadType = {
  data: {
    key: string;
    uploadid: string;
  }
}

// S3 API UploadPart
export const uploadPart = async (params: Params) => {
  const { bucket, filename, partnumber, uploadid, part, file, cancelToken, onUploadProgress } = params;
  return await request({
    method: 'PUT',
    pathname: `/${bucket}/${filename}`,
    query: { partNumber: partnumber, uploadId: uploadid },
    headers: {
      'content-encoding': 'identity',
    },
    cancelToken,
    onUploadProgress,
    unsignedBody: true,
    body: part,
    file,
  });
}

// S3 API ListMultipartUploads
export const listMultipartUploads = async (params: Params) => {
  const { bucket } = params;
  const rlt = await request({
    pathname: `/${bucket}`,
    query: { uploads: '' },
  })
  return rlt;
}

export const listParts = async (params: Params) => {
  const { bucket, pathname, uploadid, filename } = params;
  const rlt = await request({
    pathname: pathname ? pathname : `/${bucket}/${filename}`,
    query: { uploadId: uploadid },
  })
  return rlt;
}

export const abortMultipartUpload = async (params: Params) => {
  const { bucket, pathname, uploadid, filename } = params;
  const rlt = await request({
    method: 'DELETE',
    pathname: pathname ? pathname : `/${bucket}/${filename}`,
    query: { uploadId: uploadid },
  })
  return rlt;
}

