import './style.scss';
import React from 'react';
import Copyright from '../../Components/Copyright';
import logo from '../../imgs/logo.png';
import version from '../../utils/version';

export default () => {
  return (
    <div className="AboutProduct">
      <div className="AboutProduct__logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="AboutProduct__version">版本号：{version()}</div>
      <div className="Form__footer">
        <Copyright />
      </div>
    </div>
  );
};
