// 参考
// https://virtualbrakeman.wordpress.com/2017/02/13/aws-rest-api-authentication-using-node-js/
// https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
// https://github.com/mhart/aws4/blob/master/aws4.js

import crypto from 'crypto';
import { Method } from 'axios';
import { getCookie } from '../utils/cookie';

export const region = '';

export type Quest = {
  responseType?: string;
  accessKey?: string
  secretKey?: string
  endpoint?: string
  method?: Method
  expires?: number
  pathname?: string
  headers?: object
  query?:  object
  body?: any
  file?: any
  onUploadProgress?: any;
  onDownloadProgress?: any;
  cancelToken?: any;
  unsignedBody?: boolean;
}

export type Headers = {
  'x-amz-content-sha256': string;
  'x-amz-date': string,
  authorization: string,
  credential?: string,
  'content-type'?: string,
  'content-encoding'?: string;
}

export default function getHeaders(req: Quest, show?: boolean): Headers {
  // YYYYMMDDTHHMMSSZ
  const amzDate = getAmzDate(new Date().toISOString());
  const authDate = amzDate.split("T")[0];
/**
 * Canonical Request
 */
  const body = req ? (req.body || '') : '';
  const hashedPayload = req.unsignedBody ? 'UNSIGNED-PAYLOAD' : hash(body, 'hex');
  const header = {
    'x-amz-content-sha256': hashedPayload,
    'x-amz-date': amzDate,
  };
  let queryStr = '';
  if (typeof req.query === 'object') {
    let query: any = req.query;
    const reducedQuery = Object.keys(req.query as object).sort().reduce((obj: any, key: any) => {
      if (!key) return obj
      // @ts-ignore
      obj[key] = !Array.isArray(query[key]) ? query[key] : query[key][0];
      return obj
    }, {})
    var encodedQueryPieces: any[] = []
    Object.keys(reducedQuery).forEach(function (key) {
      const encodedPrefix = encodeURIComponent(key) + '='
      if (!Array.isArray(reducedQuery[key])) {
        encodedQueryPieces.push(encodeRfc3986(encodedPrefix + encodeURIComponent(reducedQuery[key])))
      } else {
        reducedQuery[key].forEach(function (v: any) { encodedQueryPieces.push(encodeRfc3986(encodedPrefix + encodeURIComponent(v))) })
      }
    })
    queryStr = encodedQueryPieces.sort().join('&')

  }
  const request = {
    method: req.method || 'GET',
    pathname: req.pathname || '/',
    query: queryStr,
    header,
    bodyhash: hashedPayload
  }
  let pathStr = request.pathname;
  if (pathStr !== '/') {
    // 修复中文乱码问题
    pathStr = encodeRfc3986(encodeURIComponent(pathStr)).replace(/%2F/g, '/');
  }
  const canonicalReq = [
    request.method,
    pathStr,
    request.query,
    canonicalHeaders(request) + '\n',
    signedHeaders(request),
    request.bodyhash
  ].join('\n');

  /**
   * String TO Sign
   */
  const canonicalReqHash = hash(canonicalReq, 'hex');
  const stringToSign = [
    'AWS4-HMAC-SHA256',
    amzDate,
    authDate + '/' + region + '/s3/aws4_request',
    canonicalReqHash
  ].join('\n');

  /**
  * Stringature
  */
  const datas = getCookie();
  const signature = getSignature(stringToSign, req.secretKey || datas.secretKey, authDate);
  const credentialPath = amzCredentialPath(req.accessKey || datas.accessKey, authDate);
  const authorization = `AWS4-HMAC-SHA256 Credential=${credentialPath}, SignedHeaders=${signedHeaders(request)}, Signature=${signature}`;
  let headers: any = {
    'x-amz-content-sha256': hashedPayload,
    'x-amz-date': amzDate,
    authorization,
    'content-type': 'application/octet-stream', //'application/x-www-form-urlencoded',
    ...req.headers,
  }
  if (show) {
    headers.credential = credentialPath;
  }
  return headers;
}

function getSignature(stringToSign: string, secretKey: string, authDate: string) {
  const dateKey = hmac('AWS4' + secretKey, authDate);
  const dateRegionKey = hmac(dateKey, region);
  const dateRegionServiceKey = hmac(dateRegionKey, 's3');
  const signingKey = hmac(dateRegionServiceKey, 'aws4_request');

  return hmac(signingKey, stringToSign).toString('hex');
}

function amzCredentialPath(accessKey: string, authDate: string) {
  return accessKey + '/' + authDate + '/' + region + '/s3/aws4_request';
}

function hash(string: string, encoding: any) {
  return crypto.createHash('sha256').update(string, 'utf8').digest(encoding)
}

function hmac(key: string, string: string) {
  const hmac = crypto.createHmac('sha256', key);
  hmac.end(string);
  return hmac.read();
}

// this function converts the generic JS ISO8601 date format to the specific format the AWS API wants
export function getAmzDate(dateStr: string) {
  const chars = [":", "-"];
  for (let i = 0; i < chars.length; i++) {
    while (dateStr.indexOf(chars[i]) !== -1) {
      dateStr = dateStr.replace(chars[i], "");
    }
  }
  dateStr = dateStr.split(".")[0] + "Z";
  return dateStr;
}

function canonicalHeaderValues(values: any) {
  return values.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
}
function canonicalHeaders(request: any) {
  let headers = [];
  for (let i in request.header) {
    headers.push([i, request.header[i]]);
  }
  headers.sort(function (a, b) {
    return a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1;
  });

  let parts = [];
  for (let i in headers) {
    const key = headers[i][0].toLowerCase();
    const value = headers[i][1];
    parts.push(key + ':' + canonicalHeaderValues(value.toString()));
  }
  return parts.join('\n');
}

function signedHeaders(request: any) {
  return Object.keys(request.header)
    .map(function (key) { return key.toLowerCase() })
    .sort()
    .join(';')
}

// This function assumes the string has already been percent encoded
// https://github.com/m59peacemaker/js-encode-3986/blob/master/index.js
function encodeRfc3986(urlEncodedString: string) {
  return urlEncodedString.replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase()
  })
}
