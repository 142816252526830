import React from 'react';
import { ObjectMessageType, setObjectMessage, deleteObjectMessage, deleteObjectMessageWithStatus, Status, UPLOAD_MAP } from '../../utils/object-message';
import { Button, Icon, ProgressBar, Tabs, Tooltip } from 'wizard-ui';
import xbytes from '../../utils/xbytes';
import NotificationContent from '../../Context/Notification';
import handleNotification from '../../utils/handle-notification';
import { abortMultipartUpload } from '../../apis/object';
import './style.scss';

type Props = {
  messages: ObjectMessageType[];
  getObjectMessage: () => void;
  uid?: string
};

export default (props: Props) => {
  const { messages, getObjectMessage, uid } = props;
  const { setNotification } = React.useContext(NotificationContent);
  const handleClick = React.useCallback(
    async (m: ObjectMessageType) => {
      if (m.rlt && ['process', 'pre-process'].includes(m.rlt) && m.action === 'upload') {
        setObjectMessage({
          ...m,
          rlt: 'fail'
        });
        // 正在上传的分段需要清理
        if (m.part && m.process) {
          await Promise.all(
            m.process.map(async p => {
              const deleteRlt = await abortMultipartUpload({
                pathname: `${m.path}/${p.path}`,
                uploadid: p.uploadid,
              });
              if (deleteRlt.statusText === 'No Content') {
                handleNotification({ status: 200 }, setNotification, `${p.path} 分段残留已经清理`);
              }
            })
          );
        }
      } else {
        deleteObjectMessage(m.key);
      }
      getObjectMessage();
    },
    [getObjectMessage, setNotification],
  );
  const [tab, setTab] = React.useState<Status>('process');
  const handleSelect = (k: any) => setTab(k);
  const [close, setClose] = React.useState<boolean>(true);
  const [len, setLen] = React.useState<number>(messages.length);

  React.useEffect(() => {
    setLen(messages.length);
    // 上传时触发面板打开
    if (close && len && messages.length - len >= 1) {
      setClose(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length, len])
  const handleClose = React.useCallback(() => {
    setClose(true);
  }, [setClose]);
  const handleDelete = React.useCallback(() => {
    deleteObjectMessageWithStatus(tab);
    getObjectMessage();
  }, [tab, getObjectMessage]);

  if (!messages.length) return null;
  let processNum = 0;
  let successNum = 0;
  let failNum = 0;
  let filterMess: ObjectMessageType[] = [];
  messages.forEach(m => {
    if (m.rlt && ['process', 'pre-process'].includes(m.rlt) && m.uuid === uid) {
      processNum++;
    } else if (m.rlt === 'success') {
      successNum++;
    } else if (m.rlt === 'fail') {
      failNum++;
    }
    if (m.rlt && ['process', 'pre-process'].includes(m.rlt)) {
      if (tab === 'process' && m.uuid === uid) {
        // 进行中的任务排在前面
        filterMess = m.rlt === 'pre-process' ? [...filterMess, m] : [m, ...filterMess];
      }
    } else if (m.rlt === tab) {
      filterMess = [...filterMess, m];
    }
  });
  const tabs = [
    {
      title: `进行中(${processNum})`,
      key: 'process',
      children: null,
    },
    {
      title: `成功(${successNum})`,
      key: 'success',
      children: null,
    },
    {
      title: `失败(${failNum})`,
      key: 'fail',
      children: null,
    },
  ];
  return (
    <div className="ObjectMessage">
      <div className="ObjectMessage__Bar">
        任务（进行中 {processNum} 个，成功 {successNum} 个，失败 {failNum} 个），
        <Button bsStyle="link" onClick={() => setClose(!close)}>
          {close ? '查看详情 >' : '关闭详情 <'}
        </Button>
      </div>
      {!close && (
        <div className="ObjectMessage__Detail">
          <header className="ObjectMessage__header">
            <h4>任务管理</h4>
            <span className="link" onClick={handleClose}>
              <Icon type="close" />
            </span>
          </header>
          <div className="ObjectMessage__content">
            <Tabs onSelect={handleSelect} size="small" activeKey={tab} tabs={tabs} />
            {filterMess.length > 0 && tab !== 'process' && (
              <div className="ObjectMessage__content--delete">
                <Button bsStyle="default" onClick={handleDelete}>全部删除</Button>
              </div>
            )}
            {filterMess.length > 0 ? (
              filterMess.map(m => {
                let now = m.addSize || 0;
                let max = m.totalSize || 0;
                let rlt = '';
                if (m.rlt && m.action === 'upload') {
                  rlt = UPLOAD_MAP[m.rlt];
                }
                if (m.rlt === 'success' && now === 0 && max === 0) {
                  now = 1;
                  max = 1;
                }
                const speed = (m.speed ? xbytes(m.speed) : '0B') + '/s';
                return (
                  <div key={m.key} className="ObjectMessage__item">
                    <div className="ObjectMessage__item-data">
                      <div
                        className="ObjectMessage__item-data-name"
                        title={m.objectName}
                      >
                        {(m.total || 1) > 1 ? `总共 ${m.total} 个文件，${m.success} 个上传成功` : m.objectName}
                      </div>
                      <Tooltip placement="top" label={(
                        <Button bsStyle="link" onClick={() => handleClick(m)}>
                          <span className="icon icon-remove" />
                        </Button>
                      )}>
                        {(m.rlt && ['process', 'pre-process'].includes(m.rlt)) ? '取消' : '移除'}
                      </Tooltip>
                    </div>
                    <ProgressBar
                      bsStyle={m.rlt === 'fail' ? 'danger' : 'success'}
                      now={now}
                      max={max}
                    />
                    <div className="ObjectMessage__item-data">
                      <span className={m.rlt}>
                        {m.rlt === 'success' && <Icon type="check-circle" />}
                        {m.rlt === 'fail' && <Icon type="times-circle" />}
                        {rlt}
                      </span>
                      <span>
                        {m.rlt === 'process' && (
                          <span className="ObjectMessage__speed">{speed}</span>
                        )}
                      </span>
                      <span className="text">
                        {xbytes(now)}/{xbytes(max)}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="EmptyPage">
                <div className="EmptyPage__icon">
                  <span className="icon icon-empty" />
                </div>
                暂无数据
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
