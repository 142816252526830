import xml2js from 'xml2js';
import store from 'store';
import { OwnerType } from '../apis/type';
import { XMLNS, XSI_XMLNS, LOG_XMLNS, ALL_USER_URI, VersionType, AclType } from '../constants';

const user = store.get('user');

export const bucketLogDelivery2xml = (bucketLogConfig:any) => {
  const grants = Array.isArray(bucketLogConfig) ? bucketLogConfig : [bucketLogConfig];
  let owner;
  // 保证桶本身权限不会缺失
  let primaryGrants = grants.map(grant => {
    if (grant.permission === 'FULL_CONTROL') {
      owner = {
        ID: grant.grantee.id || user,
        DisplayName: grant.grantee.displayname || user,
      };
      return {
        Grantee: {
          $: {
            "xmlns:xsi": XSI_XMLNS,
            "xsi:type": 'CanonicalUser'
          },
          ...owner,
        },
        Permission: 'FULL_CONTROL'
      };
    } else if (grant.grantee.uri && grant.grantee.uri.includes('AllUsers')) {
      return {
        Grantee: {
          $: {
            "xmlns:xsi": XSI_XMLNS,
            "xsi:type": 'Group'
          },
          URI: ALL_USER_URI
        },
        Permission: grant.permission
      };
    } else {
      return null;
    }
  }).filter(grant => grant);
  const builder = new xml2js.Builder();
  return builder.buildObject({
    AccessControlPolicy : {
      $: {
        "xmlns": XMLNS,
      },
      Owner: owner,
      AccessControlList: {
        Grant: [
          ...primaryGrants,
          {
            Grantee: {
              $: {
                "xmlns:xsi": XSI_XMLNS,
                "xsi:type": 'Group'
              },
              URI: LOG_XMLNS
            },
            Permission: 'WRITE'
          },
          {
            Grantee: {
              $: {
                "xmlns:xsi": XSI_XMLNS,
                "xsi:type": 'Group'
              },
              URI: LOG_XMLNS
            },
            Permission: 'READ_ACP'
          },
        ]
      }
    },
  });
}

export const version2xml = (status: VersionType) => {
  const builder = new xml2js.Builder();
  return builder.buildObject({
    VersioningConfiguration: {
      $: {
        "xmlns": XMLNS,
      },
      Status: status
    },
  });
}

export type LogType = {
  targetBucket?: string
  prefix?: string
}

type logConfig = {
  $: object;
  LoggingEnabled?: object;
}
export const log2xml = (log: LogType) => {
  const builder = new xml2js.Builder();
  const config: logConfig = {
    $: {
      "xmlns": XMLNS,
    }
  };
  if (Object.keys(log).length > 0) {
    config['LoggingEnabled'] = {
      'TargetBucket': log.targetBucket,
      'TargetPrefix': log.prefix
    }
  }
  return builder.buildObject({
    BucketLoggingStatus: config
  });
}

export type AclSubmitConfig = {
  acl?: AclType;
  grants: any;
  owner?: OwnerType;
}
export const acl2xml = (values: AclSubmitConfig) => {
  const { acl, grants, owner: cOwner } = values;
  const owner = {
    ID: cOwner ? cOwner.id : user,
    DisplayName: cOwner ? cOwner.displayname : user,
  };
  let newGrants = [];
  // 桶权限修改调整
  if (acl && ['public-read', 'public-read-write'].includes(acl)) {
    newGrants.push({
      Grantee: {
        $: {
          "xmlns:xsi": XSI_XMLNS,
          "xsi:type": 'Group'
        },
        URI: ALL_USER_URI
      },
      Permission: 'READ'
    });
  }
  if (acl && acl === 'public-read-write') {
    newGrants.push({
      Grantee: {
        $: {
          "xmlns:xsi": XSI_XMLNS,
          "xsi:type": 'Group'
        },
        URI: ALL_USER_URI
      },
      Permission: 'WRITE'
    });
  }
  let rlt: any[] = [];
  grants.forEach((grant: any) => {
    if (!grant) {} else if (grant.grantee.id && grant.grantee.id === user) { // 当前用户完全控制
      const curUser = {
        ID: grant.grantee.id || user,
        DisplayName: grant.grantee.displayname || user,
      };
      const isRepeat = rlt.some((r: any) => r.Grantee.ID === grant.grantee.id && r.Permission === grant.permission);
      if (!isRepeat) {
        rlt.push({
          Grantee: {
            $: {
              "xmlns:xsi": XSI_XMLNS,
              "xsi:type": 'CanonicalUser'
            },
            ...curUser,
          },
          Permission: grant.permission
        });
      }
    } else if (!acl && grant.grantee.uri && grant.grantee.uri.includes('AllUsers')) { // 公共访问权限
      const isRepeat = rlt.some((r: any) => r.Grantee.URI === ALL_USER_URI  && r.Permission === grant.permission);
      if (!isRepeat) {
        rlt.push({
          Grantee: {
            $: {
              "xmlns:xsi": XSI_XMLNS,
              "xsi:type": 'Group'
            },
            URI: ALL_USER_URI
          },
          Permission: grant.permission
        });
      }
    } else if (grant.grantee.emailaddress) { // 其他用户通过邮件设置权限
      const isRepeat = rlt.some((r: any) => r.Grantee.EmailAddress === grant.grantee.emailaddress && r.Permission === grant.permission);
      if (!isRepeat) {
        rlt.push({
          Grantee: {
            $: {
              "xmlns:xsi": XSI_XMLNS,
              "xsi:type": 'AmazonCustomerByEmail'
            },
            EmailAddress: grant.grantee.emailaddress
          },
          Permission: grant.permission
        });
      }
    } else if (grant.grantee.id) { // 其他用户通过名称或者 ID 设置权限
      const isRepeat = rlt.some((r: any) => r.Grantee.ID === grant.grantee.id && r.Permission === grant.permission);
      if (!isRepeat) {
        rlt.push({
          Grantee: {
            $: {
              "xmlns:xsi": XSI_XMLNS,
              "xsi:type": 'CanonicalUser'
            },
            ID: grant.grantee.id,
          },
          Permission: grant.permission
        });
      }
    } else { // 日志传输权限
      const isRepeat = rlt.some((r: any) => r.Grantee.URI === LOG_XMLNS && r.Permission === grant.permission);
      if (!isRepeat) {
        rlt.push({
          Grantee: {
            $: {
              "xmlns:xsi": XSI_XMLNS,
              "xsi:type": 'Group'
            },
            URI: LOG_XMLNS
          },
          Permission: grant.permission
        });
      }
    }
  })
  const builder = new xml2js.Builder();
  return builder.buildObject({
    AccessControlPolicy: {
      $: {
        "xmlns": XMLNS,
      },
      Owner: owner,
      AccessControlList: {
        Grant: [...rlt, ...newGrants]
      }
    },
  });
}

type Part = {
  partNumber: number;
  eTag: string;
}

export const part2xml = (parts: Part[]) => {
  const builder = new xml2js.Builder();
  const partsFormat = parts.map(p => ({
    Part: {
      PartNumber: p.partNumber,
      ETag: p.eTag
    }
  }))
  return builder.buildObject({
    CompleteMultipartUpload: partsFormat,
  });
}