import queryString from 'query-string';
import xml2js from 'xml2js';
import axios from 'axios';
import getHeaders, { Quest, Headers } from './get-headers';
import { getCookie } from '../utils/cookie';

axios.interceptors.request.use(config => {
	if (config.method && config.method.toLowerCase() === 'get') {
		//  给data赋值以绕过if判断
		config.data = true;
	}
	return config
}, err => Promise.reject(err));

const OPTIONS = {  // options passed to xml2js parser
	explicitCharkey: false, // undocumented
	trim: false,            // trim the leading/trailing whitespace from text nodes
	normalize: false,       // trim interior whitespace inside text nodes
	explicitRoot: false,    // return the root node in the resulting object?
	emptyTag: null,         // the default value for empty nodes
	explicitArray: false,    // always put child nodes in an array
	ignoreAttrs: true,     // ignore attributes, only create text nodes
	mergeAttrs: false,      // merge attributes and child elements
	normalizeTags: true,  // key 小写
};
const parser = new xml2js.Parser(OPTIONS);

export type FetchDatas = {
  status?: number | string,
  statusText?: string,
  data?: object,
  error?: string,
}

export const HTTP = 'http://';

export const asyncFetch = async (quest: Quest, headers: Headers): Promise<FetchDatas> => {
  const cacheDatas = getCookie();
  const { endpoint = '', method = 'GET', pathname = '/', query = {}, body, file, responseType, cancelToken, onUploadProgress, onDownloadProgress } = quest || {};
  if (endpoint.includes(window.location.host)) {
    return {
      error: '访问入口不正确'
    }
  }
  const queryStr = queryString.stringify(query as any);
  try {
    const ep = endpoint || cacheDatas.endpoint;
    const url = (/https?:\/\//.test(ep) ? '' : HTTP) + ep + encodeURIComponent(pathname).replace(/%2F/g, '/') + (queryStr ? `?${queryStr}` : '');
    // console.info('Request: ', `${method} ${url}`);
    let dataBody: any = {
      method,
      url,
      headers,
    }
    // upload
    if (body) {
      if (file) {
        dataBody.data = file;
      } else {
        dataBody.data = body;
      }
    }
    // 图片文件 Get 请求时需要携带
    // https://github.com/axios/axios/issues/513
    if (responseType) {
      dataBody.responseType = responseType;
    }
    // 上传监听
    if (onUploadProgress) {
      dataBody.onUploadProgress = onUploadProgress;
    }
    // 下载监听
    if (onDownloadProgress) {
      dataBody.onDownloadProgress = onDownloadProgress;
    }
    if (cancelToken) {
      dataBody.cancelToken = cancelToken;
    } else {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const cancelToken = source.token;
      dataBody.cancelToken = cancelToken;
      window.addEventListener('offline', () => {
        source.cancel(`网络错误`);
      });
    }
    dataBody.timeout = 5 * 60 * 1000;
    const response = await axios(dataBody);
    let datas: FetchDatas;
    parser.parseString(response.data, (err: any, result: any) => {
      datas = {
        ...response,
        data: result,
      };
    });
    // @ts-ignore
    return datas;
  }
  catch (error) {
    let message = '';
    let status = '404';
    let statusText = 'Fail';
    if (error.response) {
      status = String(error.response.status);
      statusText = error.response.statusText;
      if (status === '403' || statusText === 'Forbidden') {
        message = '没有权限';
      } else if (status === '502') {
        message = '网关出错';
      } else {
        parser.parseString(error.response.data, (err: any, result: any) => {
          switch (result && result.code) {
            case 'NoSuchKey':
              message = '没有获取到该对象信息';
              break;
            case 'InvalidBucketName':
              message = '存储桶名称不合法';
              break;
            case 'AccessForbidden':
              message = '访问拒绝';
              break;
            case 'MethodNotAllowed':
              message = '方法不允许';
              break;
            case 'NoSuchBucket':
              message = '没有这个桶';
              break;
            case 'AccessDenied':
              message = '没有权限';
              break;
            case 'InvalidArgument':
              message = '非法参数';
              break;
            case 'InvalidAccessKeyId':
              message = '您填写的访问密钥不正确。';
              break;
            case 'InvalidPrefix':
              message = '非法前缀。';
              break;
            case 'SignatureDoesNotMatch':
              message = '您的身份验证信息有误，请重试。';
              break;
            case 'BucketAlreadyExists':
              message = '该存储桶已存在，或被其他对象用户占用。';
              break;
            case 'XAmzContentSHA256Mismatch':
              message = '内容匹配失败';
              break;
            case 'BucketNotEmpty':
              message = '存储桶不为空，请先清空存储桶。';
              break;
            case 'InvalidTargetBucketForLogging':
              message = '桶访问日志目标桶不合法。';
              break;
            case 'UnresolvableGrantByEmailAddress':
              message = '账号 ID/邮箱不存在。';
              break;
            default:
              message = result && result.code;
          }
          if (result && result.message) {
            message = result.message;
          }
        });
      }
    }
    else {
      message = '端口不合法或者网络错误。';
    }
    if (axios.isCancel(error)) {
      message = error.message;
    }
    return {
      error: message,
      status,
      statusText,
    };
  }
}

export default async (quest: Quest = {}) => {
  const headers = getHeaders(quest);
  return await asyncFetch(quest, headers);
}