import React from 'react';

export type Notification = {
  lists: Map<any, any>;
};

const NotificationContext = React.createContext({
  lists: new Map(),
  setNotification: (n: object) => {},
  deleteNotification: (i: string) => {},
});
export default NotificationContext;
