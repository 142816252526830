import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ModalContext, { Modal as ModalType } from '../../Context/Modal';
import NotificationContent from '../../Context/Notification';
import { Modal, NotificationList } from 'wizard-ui';
import ObjectMessage from '../../Components/ObjectMessage';
import { abortMultipartUpload } from '../../apis/object';
import Topbar from '../../Components/Topbar';
import TopAlert from '../../Components/TopAlert';
import store from 'store';
import {
  setObjectMessage,
  OBJECT_MESSAGE_STORE,
  ObjectMessageType,
} from '../../utils/object-message';
import './style.scss';
import { getCookie } from '../../utils/cookie';
import { getSetting } from '../../utils/setting';
import PQueue from 'p-queue';
import uuid from '../../utils/uuid';
import loadable from '@loadable/component';

// 按需加载
const Login = loadable(() => import('../../Pages/Login'));
const Account = loadable(() => import('../Account'));
const Setting = loadable(() => import('../Setting'));
const List = loadable(() => import('../List'));
const Detail = loadable(() => import('../Detail'));
const ObjectDetail = loadable(() => import('../ObjectDetail'));

const PrivateRoute = ({ children, ...rest }: any) => (
  <Route
    {...rest}
    render={() => {
      const curCookies = getCookie();
      const isLogin = Object.keys(curCookies).length > 0;
      if (isLogin && rest.path === '/login') {
        return <Redirect to="/buckets" />;
      } else if (!isLogin && rest.path !== '/account' && rest.path !== '/login') {
        return <Redirect to="/login" />;
      } else {
        return children;
      }
    }}
  />
);

export default () => {
  const curLocation = useLocation();
  const [queue, setQueue] = React.useState<any>();
  React.useEffect(() => {
    const { concurrent: { uploadConcurrent } } = getSetting();
    const smallFilesQueue = new PQueue({ concurrency: Number(uploadConcurrent), autoStart: true });
    const largeFileQueue = new PQueue({ concurrency: 1, autoStart: true });
    setQueue({
      small: smallFilesQueue,
      large: largeFileQueue,
    });
  }, []);
  const [modal, setModal] = React.useState<ModalType[]>([]);
  const [uid, setUid] = React.useState<string>();
  const curCookies = getCookie();
  const isLoginPage = curLocation.pathname === '/login';
  const isLogin = Object.keys(curCookies).length > 0;
  const pushModal = (m: ModalType) => {
    // TODO(kailang)
    // setModal([...modal, m]);
    setModal([m]);
  };
  const popModal = () => {
    const newModal = modal.slice(0, modal.length - 1);
    setModal(newModal);
  };
  const [lists, setLists] = React.useState(() => {
    const notifications = new Map();
    notifications.set('request', new Map());
    return notifications;
  });
  const setNotification = (s: object) => {
    const id = uuid();
    const item = { ...s, id };
    lists.get('request').set(id, item);
    const newLists = new Map(lists);
    setLists(newLists);
  };
  const deleteNotification = (id: string) => {
    lists.get('request').delete(id);
    const newLists = new Map(lists);
    setLists(newLists);
  };
  const [messages, setMessage] = React.useState<ObjectMessageType[]>([]);
  const getObjectMessage = React.useCallback(
    () => {
      setMessage(store.get(OBJECT_MESSAGE_STORE) || []);
    },
    [setMessage],
  );

  React.useEffect(() => {
    setMessage(store.get(OBJECT_MESSAGE_STORE) || []);
  }, []);

  const [topAlert, setTopAlert] = React.useState<boolean>(false);
  React.useEffect(() => {
    window.addEventListener('offline', () => setTopAlert(true));
    window.addEventListener('online', () => setTopAlert(false));
  }, []);

  const [isLock, setLock] = React.useState<Boolean>(false);

  const transitionLock = React.useCallback(event => {
    event = event || window.event;
    return (event.returnValue = true);
  }, []);

  const taskDismiss = React.useCallback(() => {
    const curMess: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || [];
    if (isLogin && curMess.length > 0) {
      curMess
        .filter(c => c.rlt && ['process', 'pre-process'].includes(c.rlt) && c.uuid === uid)
        .forEach(c => {
          setObjectMessage({
            ...c,
            rlt: 'fail',
          });
          // 清理分段残留
          if (c.part) {
            abortMultipartUpload({
              pathname: `${c.path}/${c.objectName}`,
              uploadid: c.uploadid,
            });
          }
        });
    }
  }, [isLogin, uid]);

  React.useEffect(() => {
    setUid(uuid());
  }, []);
  React.useEffect(() => {
    if (!isLock && !isLoginPage && messages.filter(c => c.rlt && ['process', 'pre-process'].includes(c.rlt) && c.uuid === uid).length > 0) {
      setLock(true);
      window.addEventListener('beforeunload', transitionLock);
      window.addEventListener('unload', taskDismiss);
    }
    if (isLock && messages.filter(c => c.rlt && ['process', 'pre-process'].includes(c.rlt) && c.uuid === uid).length === 0) {
      setLock(false);
      window.removeEventListener('beforeunload', transitionLock);
      window.removeEventListener('unload', taskDismiss);
    }
  }, [isLock, isLogin, messages, taskDismiss, isLoginPage, transitionLock, uid]);

  const ROUTE_MAP = [
    {
      path: '/login',
      exact: true,
      component: () => <Login />,
    },
    {
      path: '/account',
      exact: true,
      component: () => <Account />,
    },
    {
      path: '/settings',
      exact: true,
      component: () => <Setting queue={queue} setQueue={setQueue} />,
    },
    {
      path: ['/buckets', '/cache-buckets'],
      exact: true,
      component: () => <List />,
    },
    {
      path: [
        '/buckets/:name',
        '/buckets/:name/:prefix+(/)',
        '/buckets/:name/:rootPrefix*(/)',
        '/buckets/:name//:rootPrefix+(/)',
        '/cache-buckets/:name',
        '/cache-buckets/:name/:prefix+(/)',
        '/cache-buckets/:name/:rootPrefix*(/)',
        '/cache-buckets/:name//:rootPrefix+(/)',
      ],
      exact: true,
      component: () => <Detail getObjectMessage={getObjectMessage} uid={uid} queue={queue} setQueue={setQueue} />,
    },
    {
      path: [
        '/buckets/:name/:prefix+',
        '/buckets/:name//:rootPrefix+',
        '/cache-buckets/:name/:prefix+',
        '/cache-buckets/:name//:rootPrefix+',
      ],
      component: () => <ObjectDetail />,
    },
  ];
  return (
    <NotificationContent.Provider value={{ lists, setNotification, deleteNotification }}>
      <ModalContext.Provider value={{ modal, pushModal, popModal }}>
        <NotificationList notifications={lists} onDismiss={deleteNotification} autoClose />
        {topAlert && <TopAlert title="当前网络不可用，请检查你的网络设置" />}
        {modal.map(({ content, ...rest }, index) => (
          <Modal key={index} {...rest} show hideFooter>
            {content}
          </Modal>
        ))}
        {isLogin && <ObjectMessage messages={messages} getObjectMessage={getObjectMessage} uid={uid} />}
        <Switch>
          {ROUTE_MAP.map(r => (
            <PrivateRoute exact={r.exact || false} key={r.path} path={r.path}>
              {isLoginPage ? (
                r.component()
              ) : (
                <div className={`${messages.length > 0 ? 'Dashboard__up' : 'Dashboard'}`}>
                  <Topbar />
                  {r.component()}
                </div>
              )}
              {}
            </PrivateRoute>
          ))}
          <Route path="*">
            <Redirect to="/buckets" />
          </Route>
        </Switch>
      </ModalContext.Provider>
    </NotificationContent.Provider>
  );
};
