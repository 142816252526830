import store from 'store';

export type Status = 'success' | 'fail' | 'process' | 'pre-process';

export const UPLOAD_MAP = {
  'pre-process': '等待上传...',
  process: '正在上传',
  success: '上传成功',
  fail: '上传失败',
}

export type ObjectMessageType = {
  key: string;
  uuid?: string;
  uploadid?: string;
  action?: 'upload' | 'download'
  path?: string;
  totalSize?: number;
  addSize?: number;
  currSize?: number;
  preSize?: number;
  process?: { path: string, uploadid: string }[];
  total?: number;
  success?: number;
  part?: string;
  objectName?: string;
  rlt?: Status;
  preTime?: number;
  time?: number;
  totalTime?: number;
  speed?: number;
};

const curUser = store.get('user');

export const OBJECT_MESSAGE_STORE = `object_messages_${curUser}`;

export const addObjectMessage = (m: ObjectMessageType) => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || []
  const newMessages = [m, ...messages];
  store.set(OBJECT_MESSAGE_STORE, newMessages)
}

export const setObjectMessage = (m: ObjectMessageType) => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || []
  const newMessages = messages.map((message) => {
    if (message.key === m.key) {
      // 文件大小累计
      m.addSize = (m.currSize || 0) + (message.addSize || 0);
      // 文件成功数量累计
      m.success = (m.success || 0) + (message.success || 0);
      // 分段上传任务累计
      if (m.process) {
        if (!message.process) {
          message.process = [];
        }
        const curProcess = m.process[0];
        if (!message.process.some(p => p.uploadid === curProcess.uploadid)) {
          m.process = [...message.process, curProcess];
        }
      }
      return {
        ...message,
        ...m,
      }
    }
    return message
  });
  store.set(OBJECT_MESSAGE_STORE, newMessages)
};

export const deleteObjectMessage = (key: string) => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || [];
  const newMessages = messages.filter(m => m.key !== key);
  store.set(OBJECT_MESSAGE_STORE, newMessages)
};

export const deleteObjectMessageWithStatus = (status: Status) => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || [];
  const newMessages = messages.filter(m => m.rlt !== status);
  store.set(OBJECT_MESSAGE_STORE, newMessages)
}

export const fetchObjectMessage = (key: string) => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || [];
  const curMessage = messages.filter(m => m.key === key);
  return curMessage.length ? curMessage[0] : {};
};

export const checkObjectMessage = (key: string) => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || [];
  const curMessage = messages.filter(m => m.key === key && m.rlt === 'process');
  return curMessage.length > 0;
}

export const checkProcessTask = () => {
  const messages: ObjectMessageType[] = store.get(OBJECT_MESSAGE_STORE) || [];
  return messages.some(m => m.rlt === 'process');
}