
type Rlt = {
  error?: string;
  status?: number;
}

export default (rlt: Rlt, setNotification: any, text?: string) => {
  if (rlt.error) {
    setNotification({
      status: 'danger',
      text: rlt.error
    });
  } else if (rlt.status === 200 || rlt.status === 204) {
    setNotification({
      status: 'success',
      text
    });
  } else {
    setNotification({
      status: 'info',
      text
    });
  }
}