
// https://github.com/qiniu/kodo-browser/blob/caf0e0e48e5242418a9ebb0ef08a93d6c4585b2c/node/s3store/lib/upload-job.js#L235

// 100MB/s
// const MAX_SPEED_LIMIT = 100 * 1024 * 1024;

export default (size: number, time: number, preSpeed: number, totalTime: number, totalSize: number) => {
  let avgSpeed = (totalSize / totalTime) * 1000;
  let speed = time ? ((size || 0) * 1000) / time : 0;
  if (speed > 0) {
    if (preSpeed / speed > 1.1) {
      speed = preSpeed * 0.95;
    }
    if (speed < avgSpeed) {
      speed = avgSpeed;
    }
    // if (speed > MAX_SPEED_LIMIT) {
    //   speed = MAX_SPEED_LIMIT;
    // }
  }
  return speed;
}