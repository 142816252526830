import store from 'store';

export const ACCOUNT_STORE = `accounts`;

export type AccountType = {
    id: string;
    name: string,
    endpoint: string,
    accessKey: string,
    secretKey: string,
}

export const getAccounts = ():AccountType[] => {
  return store.get(ACCOUNT_STORE) || [];
}

export const addAccount = (s: AccountType) => {
    const accounts = getAccounts();
    const newAccounts = [s, ...accounts];
    store.set(ACCOUNT_STORE,newAccounts); 
}

export const editAccount = (s: AccountType) => {
    const accounts = getAccounts();
    const newAccounts = accounts.map(a => a.id === s.id ? s : a);
    store.set(ACCOUNT_STORE,newAccounts); 
}

export const deleteAccount = (s: AccountType) => {
    const accounts = getAccounts();
    const newAccounts = accounts.filter(a => a.id !== s.id);
    store.set(ACCOUNT_STORE,newAccounts);
}