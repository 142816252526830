
import md5 from 'js-md5';

export const fileParts = async (file: File, piece: number) => {
  const size = file.size;
  const times = Math.ceil(size / piece);
  const parts = [];
  for(let i = 0; i < times; i++) {
    const currentLen = i * piece;
    //文件 File 对象是 Blob 对象的子类，Blob 对象包含一个重要的方法slice，通过这个方法，就可以对二进制文件进行拆分。
    const currentBody = (i === times - 1) ? file.slice(currentLen) : file.slice(currentLen, currentLen + piece);
    const curArrBufferPart = await fileToArrayBuffer(currentBody);
    const currentMd5 = md5(curArrBufferPart);
    parts.push({
      partNumber: i + 1,
      eTag: currentMd5,
      body: curArrBufferPart,
      size: currentBody.size,
    });
  }
  return parts
}

// 格式化 file
export const fileToArrayBuffer = (file: File | Blob): Promise<Uint8Array> => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(new Uint8Array(reader.result as ArrayBuffer));
    };
    reader.readAsArrayBuffer(file);
  });
};

// https://github.com/feross/blob-to-buffer/blob/master/index.js
export const blobToBuffer = (blob: File | Blob): Promise<Buffer> => {
  return new Promise((resolve => {
    const reader = new FileReader()
    function onLoadEnd(e: any) {
      reader.removeEventListener('loadend', onLoadEnd, false)
      resolve(Buffer.from((reader.result as ArrayBuffer)))
  }
  reader.addEventListener('loadend', onLoadEnd, false)
  reader.readAsArrayBuffer(blob)
  }))
}

// https://stackoverflow.com/questions/35372563/how-to-merge-two-array-buffers-into-one
export const appendBuffer = (buffer1: Uint8Array, buffer2: Uint8Array) => {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

export const bufferToString = (buffer: ArrayBuffer, type: string) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  const base64 = window.btoa( binary );
  return `data:${type};base64,${base64}`;
}