
import store from 'store';

export const HTTP = 'http://';

export function clearCookie() {
  store.remove('datas')
  store.remove('user');
}

export type CookieType = {
  name?: string;
  accessKey: string
  secretKey: string
  endpoint: string
}

export function setCookie(values: CookieType) {
  let endpoint = values.endpoint;
  endpoint = (/https?:\/\//.test(endpoint) ? '' : HTTP) + endpoint;
  store.set('datas', { ...values, endpoint});
}

export function getCookie(): CookieType {
  return store.get('datas') || {};
}