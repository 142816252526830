import React from 'react';
import { Navbar, Nav, MenuItem, Icon, NavDropdown } from 'wizard-ui';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { clearCookie, getCookie, setCookie } from '../../utils/cookie';
import { getAccounts, AccountType } from '../../utils/accounts';
import handleNotification from '../../utils/handle-notification';
import NotificationContent from '../../Context/Notification';
import ModalContext from '../../Context/Modal';
import request from '../../utils/request';
import store from 'store';
import logo from '../../imgs/logo.png';
import AboutProduct from '../../Form/AboutProduct';
import './style.scss';

export default () => {
  const user = store.get('user');
  const { setNotification } = React.useContext(NotificationContent);
  const cookies = getCookie();
  const isLogin = cookies && Object.keys(cookies).length > 0;
  const { pushModal, popModal } = React.useContext(ModalContext);
  const history = useHistory();
  const accounts = getAccounts();
  const handleSelect = (eventKey: any) => {
    switch (eventKey) {
      case 2.1:
        history.push('/account');
        break;
      case 2.2:
        clearCookie();
        window.location.reload();
        break;
      default:
    }
  };
  const openProduct = React.useCallback(() => {
    pushModal({
      title: '关于产品',
      content: <AboutProduct />,
      onHide: popModal,
    });
  }, [pushModal, popModal]);
  const toggleAccount = React.useCallback(
    (a: AccountType) => async () => {
      const data: any = await request(a);
      if (data.error) {
        handleNotification({ error: `切换到“${a.name}”失败，${data.error}` }, setNotification);
      } else {
        handleNotification({ status: 200 }, setNotification, `切换到账户 ${a.name}`);
        const name = data.data.owner.displayname;
        store.set('user', name);
        setCookie(a);
        setCookie(getCookie());
        history.push('/buckets');
        window.location.reload();
      }
    },
    [history, setNotification],
  );
  return (
    <div className="Topbar">
      <Navbar onSelect={handleSelect} fluid>
        <Nav>
          <li>
            <Link to="/buckets">
              <img src={logo} alt="logo" />
            </Link>
          </li>
        </Nav>
        <Nav pullRight>
          {isLogin ? (
            <>
              <li className="Topbar__item">用户名：{user}</li>
              <li className="Topbar__item">访问入口：{cookies && cookies.endpoint}</li>
              <NavDropdown title={<Icon type="help" />} id="basic-nav-dropdown-1" noCaret>
                <MenuItem
                  onClick={() => {
                    openProduct();
                  }}
                >
                  关于产品
                </MenuItem>
              </NavDropdown>
              <li>
                <Link to="/settings?set=partition">
                  <Icon type="gear" />
                </Link>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login">返回登录页</Link>
            </li>
          )}
          <NavDropdown
            title={isLogin ? (cookies && cookies.name) || '未知账户' : '未登录'}
            id="basic-nav-dropdown-2"
          >
            {accounts
              .filter(a => a.name !== (cookies && cookies.name))
              .map(a => (
                <MenuItem key={a.id} onClick={toggleAccount(a)}>
                  {a.name}
                </MenuItem>
              ))}
            <MenuItem eventKey={2.1}>账户管理</MenuItem>
            {isLogin && <MenuItem eventKey={2.2}>退出</MenuItem>}
          </NavDropdown>
        </Nav>
      </Navbar>
    </div>
  );
};
